import React, {useState, useEffect} from 'react';
import StatusMessage from '../components/StatusMessage'
import { getAuth, onAuthStateChanged} from 'firebase/auth';
//import {useNavigate, useParams } from 'react-router-dom';
import serverPost from '../services/serverPost'
import {search} from '../services/search'
import FormTemplate from '../components/FormTemplate'
import EditTable from '../components/EditTable'
import { enhanceValueWithDraftVariables } from '../components/DraftEditor'

const tableName = 'tbl_settings'
const tableNameEmployee = 'tbl_employee'

const styles = {
    container0: {
        display: 'flex',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth:'100%', 
    },
    container:{
        display:'flex',
        paddingTop:80,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor:'rgba(32,32,32,1)',
        //color:'#FFFFFF',
        // height:'calc(100vh \- 50px)'
    },
    row:{
        display:'flex',
        backgroundColor:'rgba(32,32,32,1)',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

    },
    col:{
        display:'flex',
        paddingTop:50,
        backgroundColor:'rgba(32,32,32,1)',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}

const FIELD_TYPE = 'draft'

const fields = [
    {
        type:'number',
        label:'Bredd på kvitto',
        name:'breddKvitto',
        required:true,
        tooltip:'',
    },
    {
        type:FIELD_TYPE,
        label:'Header kundkvitto',
        name:'headerKvitto',
        required:true,
        tooltip:'Header på det kvitto som lämnas ut till kund',
    },
    {
        type:FIELD_TYPE,
        label:'Footer kundkvitto',
        name:'footerKvitto',
        required:true,
        tooltip:'Footer på den lapp som tejpas på objekted',
    },
    {
        type:'number',
        label:'Bredd på A4 papper',
        name:'breddA4',
        required:true,
        tooltip:'',
    },
    {
        type:FIELD_TYPE,
        label:'Header A4',
        name:'headerA4',
        required:true,
        tooltip:'Header på den A4 sida som lämnas ut till kund',
    },

]




export default () => {
    const [value, setValue] = useState()
    const [statusMessage, setStatusMessage] = useState({})
    const [employeeList, setEmployeeList] = useState([])

    const handleSearchReply = list => {
        if (list.length === 0) {
            handleStatus({backgroundColor:'orange'}, 'Varning: Fick inget resultat vid sökning i databasen efter inställningar')    
        } if (list.length === 1) {
            handleStatus({backgroundColor:'green'}, undefined)
            const valueWithDraft = enhanceValueWithDraftVariables(fields, list[0])
            setValue(valueWithDraft)
        } else {
            alert('Multiple records:' + JSON.stringify(list))
            handleStatus({backgroundColor:'red'}, 'Multiple records')    
        }    
    }

    const handleSearchReply1 = list => {
        if (list.length === 0) {
            handleStatus({backgroundColor:'orange'}, 'Varning: Fick inget resultat vid sökning i databasen efter anställda')    
        } else {
            handleStatus({backgroundColor:'green'}, undefined)
            setEmployeeList(list.sort((a,b)=>a.sequenceNumber - b.sequenceNumber))
        } 
    }

    useEffect(()=>{
        search(tableName, {id:1}, handleSearchReply) 
        search(tableNameEmployee, {}, handleSearchReply1) 
    }, []) 

    const handleStatus = (style, message) => {
        setStatusMessage({style, message})
    }

    const handleSaveCallback = reply => {
        const {status, record} = reply

        if (status === 'OK') {
            // alert('Följande värden är sparade i databasen: ' + JSON.stringify(value))
            handleStatus({backgroundColor:'green'}, undefined)
        } else {
            const message = 'FELMEDDELANDE: Servicerapporten kunde inte uppdateras'
            handleStatus({backgroundColor:'red'}, message)
        }    
    }

    const handleSave = value => {
        handleStatus({backgroundColor:'lightGreen'}, 'Uppdaterar inställningar i databasen')
        serverPost('/replaceRow', '', '', {tableName, record:value, id:1}, handleSaveCallback)
    }

    const buttons=[
        {
            style:{color:'black', borderColor:'black'},
            type:'button',
            label:'Spara',
            handleClick:(()=>handleSave(value))
        },    
    ]

    const resultFields = [
        {label:'Sekvens', name:'sequenceNumber', type:'text'},
        {label:'Name', name:'name', type:'text'},
        /*
        {label:'email', name:'email', type:'text'},
        {label:'autosave', name:'autosave', type:'checkbox'},
        */
    ]    
    
    
    //const strValue = JSON.stringify(value);
    
    return(    
        <div style={styles.container}>
                <>
                    <h2>Inställningar</h2>
                    {value?
                    <FormTemplate
                            buttons={buttons}
                            tableName={tableName} 
                            fields={fields} 
                            value={value} 
                            setValue={setValue}
                            handleStatus={handleStatus}  
                    />
                    :<h3>Hämtar inställningar från databasen ...</h3>}

                    <EditTable 
                            tableName={'tbl_employee'}
                            resultFields={resultFields}
                            list={employeeList} 
                            setList={setEmployeeList} 
                            handleStatus={handleStatus}  
                            addColumns={['name']}
                            addFlag
                            deleteFlag
                    />

                    <StatusMessage style={statusMessage.style} message={statusMessage.message} />
                </>
        </div>
    )
} 