import React, {useState, useEffect} from 'react';
import serverPost from '../services/serverPost'
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const styles={
    container: {
        display: 'flex',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth:'100%', 
    },
    button:{
        color:'black',
        border:'1px solid red'
    },
    tr:{
        borderBottom:'1px solid grey'

    },
    th:{
        padding:3
    },
    td:{
        padding:3
    }

}

  
export default props => {
    const [edit, setEdit] = useState([])
    const {searchFields, resultFields, tableName, list, setList, fields, deleteFlag, addFlag, addColumns,  constants, handleSort, handleStatus} = props;
    const [value, setValue] = useState([])
    
    const handleReplySave = reply => {
        if (reply.status==='OK') {
            setEdit({})
            handleStatus({color:'white', backgroundColor:'green'}, 'Data sparade med id=' + reply.id)
            window.location.reload()
        } else {
            handleStatus({color:'white', backgroundColor:'green'}, 'Fel vid sparande av data. Meddelande:' + JSON.stringify(reply))
        }    
    }    
    const handleSave = record => {
        let recordSave = {}
        if (fields) {
            fields.forEach(it => {
                if (record[it.name]!==undefined) {
                    recordSave = {...recordSave, [it.name]:record[it.name]}
                }
            })
            if (record.id) {
                recordSave = {id:record.id?record.id:0, ...recordSave, ...constants}
            } else {
                alert('[EditTable]: No id to save')
            }    
            //alert('Saving' + JSON.stringify(recordSave))
        } else {
            // alert(JSON.stringify(record))
            recordSave = record;
        }   

        serverPost('/replaceRow', '', '', {tableName:tableName, record:recordSave}, handleReplySave)
    }

    const handleReplyDelete = reply => {
        if (reply.status==='OK') {
            const id=reply.id
            setList(list.filter(it=>it.id !==id))
            handleStatus({color:'white', backgroundColor:'green'}, reply.message)
            window.location.reload()
        } else {
            const strReply = JSON.stringify(reply)
            alert('ERROR: Failed to delete record:' + strReply)
            handleStatus({color:'white', backgroundColor:'green'}, "Fel vid borttagande av record. Meddelande:" + strReply)
        }    
    }    
    const handleDelete = id => {
        if (tableName === 'tbl_service') {
            alert("Varning: Du har inte rättigheter att ta bort rader från tabellen med sericerapporter"); 
        } else {
            let text = "VARNING: Vill du verkligen ta bort bort raden med id:" + id + " från tabellen " + tableName
            // eslint-disable-next-line no-restricted-globals
            if (confirm(text)) {
                handleStatus({color:'white', backgroundColor:'green'}, 'Remove from table ' + tableName); 
                serverPost('/deleteRow', '', '', {tableName, 'id':id}, handleReplyDelete)
            }    
        }    
    }

    const handleChange = (e, id) => setList(list.map(it=>{
        if (it.id===id) {
            const value = e.target.type==='checkbox'?(e.target.checked?1:0):e.target.value
            return {...it, [e.target.name]:value}
        } else {
            return it
        }    
    }
    ))
    const toggleEdit = id =>  {
        if (edit[id]===true) {
            setEdit({...edit, [id]:false})    
            if (handleSave) {
                const record = list.find(it=>it.id === id)
                handleSave(record)            
            }
        } else {
            setEdit({...edit, [id]:true})    
        }   
    }    

    const renderHeader = row => {
        const fields = resultFields?resultFields:searchFields?searchFields:undefined
        return(
            fields?
                fields.map(fld=>
                            <th 
                                style={styles.th} 
                                onClick={()=>handleSort?handleSort(fld):null}
                            >
                                {fld.label?fld.label:fld.name}
                           </th>
                        )
            :fields?
                fields.map(fld=><th style={styles.th}>{fld.labelResult?fld.labelResult:fld.label?fld.label:fld.name}</th>)
            :<h4>No resultFields or searchFields</h4>)    

    }    

    const handleClickLine = row => {
        if (!edit) {
            props.handleClickLine(row)
        }    
    }


    const renderRow = row => {
        const fields = resultFields?resultFields:undefined
        return(
            fields?
                <tr style={styles.tr}>
                    {fields.map(fld=>
                        <>
                            {(edit[row.id]===true)?
                                <td style={styles.td} onClick={()=>handleClickLine(row)}>
                                    <input 
                                        type={fld.type?fld.type:'text'} 
                                        name={fld.name} 
                                        checked = {row[fld.name]?1:0} 
                                        value={row[fld.name]?row[fld.name]:null} 
                                        onChange={e=>handleChange(e, row.id)} />
                                </td>
                            :
                                <td style={{...styles.td, ...fld.style?fld.style:{}}} onClick={()=>props.handleClickLine(row)}>
                                    {fld.boolText?row[fld.name]==1?fld.boolText:null:row[fld.name]?row[fld.name]:null}
                                </td>
                            }
                        </>
                    )}
                    {toggleEdit?
                        <td style={styles.td}>
                            {(edit[row.id]===true) ?<SaveIcon onClick={()=>toggleEdit(row.id)} />:<EditIcon onClick={()=>toggleEdit(row.id)} />}
                        </td>
                    :
                        null        
                    }
                    {deleteFlag?
                        <td>
                            <DeleteIcon onClick={()=>handleDelete(row.id)} />
                        </td>
                    :
                        null        
                    }
                </tr>
            :
                null
        )
    }

    const renderAdd = row => {
        const fields = resultFields?resultFields:undefined
        return(
            fields?
                <tr style={styles.tr}>
                    {fields.map(fld=>
                        fld.name ==='id'?
                            <td/>
                        :
                        <>
                            <td style={styles.td} onClick={()=>handleClickLine(row)}>
                                <input type='text' name={fld.name} value={value[fld.name]?value[fld.name]:null} onChange={e=>setValue({...value, [e.target.name]:e.target.value})} />
                            </td>
                        </>
                    )}
                    {addFlag?
                        <td>
                            <AddIcon onClick={()=>handleSave({...value, id:undefined})} />
                        </td>
                    :
                        null        
                    }
                </tr>
            :
                null
        )
    }

    const getLabel = name => {
        if (fields) {
            const found = fields.find(fld => fld.name===name)
            const label = found?found.name:name
            return label
        } else {
            return name
        }    
    }

    const emptyObject = object => {
        let obj = {}
        Object.keys(object).map(it=> obj = {...obj, [it]:undefined})
        return obj
    }



    return (
        list?list.length > 0?
            <div>
                <>
                    <table style={{border:'5px solid lightGrey', borderStyle:'outset', margin:10, maxWidth:'50vh'}}>
                        <thead style={{bottomBorder:'1px solid lightGrey', borderStyle:'outset', margin:10}}>
                            {renderHeader(list[0])}
                        </thead>
                        <tbody style={{border:'1px solid lightGrey', borderStyle:'outset', margin:10}}>
                            {list.map(li=>
                                renderRow(li)
                            )}    
                            {addFlag?renderAdd(emptyObject(list[0])):null}
                        </tbody>
                    </table>
                </>
        </div>
        :
            null:null
    )
}


